import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './approvalDetails.scss';
import { Button, Icon } from 'semantic-ui-react';
import phoneUtils from '@exuus/rwanda-phone-utils';
import StatusTag from 'components/common/StatusTag';
import capitalize from 'utils/capitalize';
import { isSuperAdmin, isSupportAdmin } from 'helpers/checkRoles';
import hasAllCommiteeApproved from 'helpers/hasAllCommiteeApproved';

const ApprovalDetails = ({ data, status, openRequestModal }) => {
  const { roles } = useSelector(
    ({
      user: {
        currentUser: { details },
      },
    }) => details,
  );

  const hasTransactionFailed = () => {
    const transaction =
      data?.transactions?.[data?.transactions?.length - 1 || 0];
    return (
      transaction?.type === 'debit' &&
      transaction?.status === 'failed'
    );
  };

  return (
    <div className="approvalDetails">
      <div className="approvalDetails__headerTop">
        <div className="approvalDetails__status">
          <span className="approvalDetails__header">Status: </span>
          <span
            className={`approvalDetails__header approvalDetails__status approvalDetails__${status}`}
          >
            {capitalize(status)}
          </span>
        </div>
        {status === 'pending' && isSuperAdmin(roles) && (
          <Button
            className="approvalDetails__button__status"
            onClick={() => openRequestModal('cancel-request')}
          >
            Cancel
          </Button>
        )}
        {isSupportAdmin(roles) &&
          ['pending', 'failed'].includes(status) &&
          hasAllCommiteeApproved(data?.approvals) && (
            <Button
              className="approvalDetails__button__status"
              onClick={() => openRequestModal('re-approve-request')}
            >
              Re-approve
            </Button>
          )}
        {hasAllCommiteeApproved(data?.approvals) &&
          hasTransactionFailed() &&
          data.type !== 'share_out' && (
            <Button
              className="approvalDetails__button__status"
              onClick={() => openRequestModal('re-trigger-request')}
            >
              Re-trigger
            </Button>
          )}
      </div>
      <div className="approvalDetails__approvals">
        <p className="approvalDetails__header">Approvals</p>
        {data?.approvals?.map(approval => (
          <div
            key={approval.id}
            className="approvalDetails__approvals__item"
          >
            <div className="approvalDetails__approvals__item__approverInfo">
              <span className="approvalDetails__approvals__item__dot">
                •
              </span>
              <span>
                {`${approval?.user?.first_name} ${approval?.user?.last_name}`}
              </span>
              <p className="approvalDetails__approvals__item__phone">
                <Icon name="phone" />{' '}
                {phoneUtils(approval?.user?.phone_number).format(
                  'space',
                )}
              </p>
            </div>
            <div className="approvalDetails__approvals__item__tag">
              <StatusTag status={approval?.status} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

ApprovalDetails.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  status: PropTypes.string.isRequired,
  openRequestModal: PropTypes.func.isRequired,
};

export default ApprovalDetails;
