import React from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import './index.scss';
import formatNumber from 'helpers/formatNumber';
import TinyBarChart from './TinyBarChart';

const BarChartContainer = ({
  title,
  date,
  data,
  count,
  background,
  height,
  dataKey,
  width,
  icon,
}) => {
  return (
    <>
      <div
        className="barchart__container"
        style={{ backgroundColor: background }}
      >
        <div className="barchart__heading">
          <div className="barchart__info">
            <h1 className="barchart__title">{title}</h1>
            <span className="barchart__date">{date}</span>
            <span className="barchart__count">{count}</span>
          </div>
          {icon && <Icon name={icon} size="big" />}
        </div>
        <TinyBarChart
          data={data}
          dataKey={dataKey}
          width={width}
          height={height}
        />
      </div>
    </>
  );
};

BarChartContainer.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Array),
  dataKey: PropTypes.string.isRequired,
  background: PropTypes.string,
  count: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  icon: PropTypes.string,
};

BarChartContainer.defaultProps = {
  background: '#FFFFFF',
  width: 200,
  height: 50,
  icon: null,
  data: [],
  count: 0,
};

export default BarChartContainer;
